.DISPLAY_NAME_INPUT--{
    background-color: #DBDBDB;
    min-height: 100vh;
    padding-top: 6rem;
    padding-bottom: 6rem;
    @media screen and (min-height: 600px) {
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .innerBox{
        width: 50rem;
        border-radius: 4rem;
        margin: auto auto;
        background-color: white;
        padding: 7rem 6rem;
        box-sizing: border-box;
        .logo{
            display: flex;
            justify-content: center;
            > img {
                width: 210px;
            }
        }
        > h2{
            font-size: 2.6rem;
            margin-top: 2.6rem;
            font-weight: 700;
            text-align: center;
        }
        .preTweaksDiv{
            display: flex;
            justify-content: center;
            position: relative;
            > video{
                width: 300px;
                height: 180px;
                object-fit: cover;
                border-radius: 1rem;
            }
            .controlButtons{
                position: absolute;
                display: flex;
                padding-top: 1rem;
                .audioVideoBtn{
                    width: 3.4rem;
                    // padding: 0px 0.9rem;
                    height: 3.4rem;
                    position: relative;
                    margin: 0px 0.4rem;
                    .bigBtn{
                        width: 3.4rem;
                        // padding: 0px 0.9rem;
                        height: 3.4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #d8d8d860;
                        > img{
                            max-width: 1.4rem;
                            max-height: 1.4rem;
                        }
                    }
                    > .miniBtn{
                        cursor: pointer;
                        position: absolute;
                        right: -4px;
                        top: -2px;
                        width: 1.4rem;
                        height: 1.4rem;
                        border-radius: 2px;
                        background: grey;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        > img {
                            width: 0.8rem;
                        }
                    }
                }
            }
        }
        .nameInputDiv{
            margin-top: 4rem;
            > label{
                display: block;
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
            }
            > input{
                height: 6rem;
                width: 100%;
                background: white;
                border-radius: 1rem;
                margin: 0px;
                border: 1px solid grey;
                outline: none;
                padding: 0px 2rem;
                box-sizing: border-box;
                font-size: 2rem;
                margin-bottom: 5rem;
            }
        }
        .joinButton{
            height: 7rem;
            font-size: 2rem;
        }
    }
}