.SIDEBAR_HEADER--{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
    padding: 0px 3rem;
    border-bottom: 1px solid #C7C5C5;
    .leftSide{
        display: flex;
        > div{
            font-size: 2rem;
            font-weight: 700;
        }
        > span{
            width: 2.6rem;
            height: 2.6rem;
            border-radius: 1.3rem;
            // display: block;
            background-color: #2AC66E;
            font-size: 1.4rem;
            color: white;
            font-weight: 700;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            margin-left: 1.2rem;
        }
    }
    button.rightClose{
        background: transparent;
        border: none;
        outline: none !important;
        transform: rotate(45deg);
        font-size: 2.7rem;
    }
}