.endMeetingModal.modal-dialog{
    margin-top: 45vh;
    transform: translate(0,-50%) !important;
    .modal-content{
        border-radius: 1rem;
        .modal-body{
            padding: 1rem 1.5rem 1.5rem;
            .titleRow{
                display: flex;
                justify-content: space-between;
                align-items: center;
                > button{
                    background: none;
                    border: none;
                    transform: rotate(45deg);
                    font-size: 3rem;
                    line-height: 1;
                    padding: 2px;
                }
            }
            .buttons{
                display: flex;
                justify-content: flex-end;
                margin-top: 2rem;
                > button{
                    width: auto;
                    padding: 0.6rem 1.2rem;
                    margin-left: 1rem;
                }
            }
        }

    }
}