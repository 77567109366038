.EACH_PARTICIPANT--{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 2rem;
    font-size: 1.4rem;
    &:hover{
        background: #eee;
    }
    .leftHandSide{
        display: flex;
        align-items: center;
        >.photoContainer{
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 1rem;
            position: relative;
            > img.profile{
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 2rem;
                background-color: grey;
                padding: 6px;
            }
            > span{
                width: 1rem;
                height: 1rem;
                border-radius: 0.5rem;
                background: #2AC66E;
                display: block;
                position: absolute;
                right: 0px;
                bottom: 0px;
            }
        }
        > .nameDetails{
            > div{
                line-height: 1.7rem;
            }
            .title{
                color: #A0A0A0;
            }
        }
    }
    .rightHandSide{
        display: flex;
        align-items: center;
        > button{
            height: 3rem;
            font-size: 1.4rem;
            padding: 0px 0.9rem;
            width: auto;
            margin-left: 0.7rem;
            display: flex;
            align-items: center;
            &.darken{
                background: #777;
            }
            > img{
                max-width: 1.6rem;
                max-height: 2rem;
            }
        }
    }
}

.participantEllipsis_popover{
    .popover-body{
        padding: 0px;
        > button{
            display: block;
            width: 100%;
            text-align: left;
            border: none;
            border-bottom: 1px solid #ccc;
            background: #fff;
            padding: 0.6rem 1rem;
            &:last-child{
                border-bottom: none;
            }
            &:hover{
                background: rgb(245, 245, 245);
            }
            > img{
                margin-right: 1rem;
            }
        }
    }
}