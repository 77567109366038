@import "../../../configs/variables";
.AD_CAROUSEL-- {
  width: 400px;
  .adsCarousel {
    position: relative;
    .carousel-inner {
      .carousel-item {
        width: 400px;
        height: 200px;
        background: linear-gradient(180deg, #14634d8e, #43dcaf8e);
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .carousel-indicators {
      display: none;
    }
  }
}
