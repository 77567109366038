
@import '../../../configs/variables';
.WAITING_FOR_HOST--{
  background: $waitingScreensBackgroundColor;
    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: calc(100vh - #{$headerHeight});
        > .topHalf{
          text-align: center;
          margin-top: 10rem;
          // > img{
          //   width: 20rem;
          //   height: 20rem;
          //   object-fit: cover;
          //   border-radius: 10rem;
          //   box-shadow: 0px 11px 10px #ccc;
          // }
          > .carouselWrapper{
            display: flex;
            justify-content: center;
          }
          > h1{
            margin-top: 6rem;
            span{
              color: #185e49;
            }
          }
          > h2{
            color: #777;
            font-weight: 500;
            font-size: 1.7rem;
          }
        }
        > h4.bottomHalf{
          .isHost{
            display: flex;
            justify-content: center;
            margin-bottom: 5rem;
            font-weight: 500;
            font-family: lato;
            > div{
              font-size: 1.6rem;
              font-weight: 500;
            }
            a{
              margin-left: 0.5rem;
              margin-right: 0.5rem;
              color: $hyperlinkTextColor;
              font-size: 1.6rem;
            }
          }
          .poweredBy{
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(109, 109, 109);
            > img{
              width: 160px;
              margin-left: 1rem;
            }
          }

        }
    }
}