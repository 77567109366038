.askedToUnmute{
    .headerRow{
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg{
            width: 35px;
            height: 40px;
        }
        > div{
            line-height: 1;
            margin-left: 1rem;
        }
    }
    .buttonsRow{
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        > button{
            margin-left: 1rem;
            width: auto;
            padding: 0.3rem 1rem;
            font-size: 1.4rem;
        }
    }
}

.customAlert.Toastify__toast-container {
    width: 30rem;
    right: 6rem;
    .Toastify__toast {
      border: 1px solid #2a9b7a;
      min-height: 45px;
      background: #eefbf7;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .Toastify__toast-body {
      color: #555;
      font-size: 1.5rem;
      // padding: 0px 1rem;
      .msg-left{
        display: flex;
        align-items: center;
        > img{
          width: 20px;
        }
        > div{
          padding-left: 1rem;
          line-height: 1.1;
        }
      }
    }
    .Toastify__close-button {
      outline: none !important;
    }
  }