.EACH_WAITING--{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.4rem;
    &:hover{
        background: #eee;
    }
    .leftHandSide{
        display: flex;
        align-items: center;
        > img.profile{
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 2rem;
            margin-right: 1rem;
            background-color: grey;
            padding: 6px;
        }
    }
    .rightHandSide{
        display: flex;
        align-items: center;
        > button{
            height: 2.8rem;
            font-size: 1.4rem;
            padding: 0px 0.9rem;
            width: auto;
            margin-left: 0.7rem;
            display: flex;
            align-items: center;
        }
    }
}