.CONFERENCE_VIEW--{
    display: flex;
    font-size: 1.4rem;
    .conference-div{
        flex: 1;
        background: #eee;
        min-height: 100vh;
        > #meet{ 
            height: 100%;
        }
    }
    .brandingLogo{
        display: block;
        position: absolute;
        left: 32px;
        top: 32px;
        > img{
            max-width: 140px;
            max-height: 70px;
        }
    }
    .wide-sidebar-wrap{
        width: 46rem;
        min-height: 100vh;
    }
    .narrow-sidebar-wrap{
        width: 6rem;
        min-height: 100vh;
    }
}