.SIDEBAR_BOTTOM_BUTTONS--{
    display: flex;
    .bottomButton{
        height: 2.8rem;
        font-size: 1.4rem;
        padding: 0px 0.9rem;
        width: auto;
        margin-right: 0.7rem;
        display: flex;
        align-items: center;
    }
}

.sidebarMore_popover{
    .popover-body{
        padding: 0px;
        > label{
            display: flex;
            > input {
                margin: 5px;
            }
        }
        > button{
            display: block;
            width: 100%;
            text-align: left;
            border: none;
            border-bottom: 1px solid #ccc;
            background: #fff;
            padding: 0.6rem 1rem;
            &:last-child{
                border-bottom: none;
            }
            &:hover{
                background: rgb(245, 245, 245);
            }
            > img{
                margin-right: 1rem;
            }
        }
    }
}