@keyframes ldio-19929li0i5u {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-19929li0i5u div {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 6px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    margin-left: 1.5rem;
  }
  .ldio-19929li0i5u div {
    animation: ldio-19929li0i5u 1s linear infinite;
    top: 50px;
    left: 50px
  }
  .loadingio-spinner-rolling-400e5tfdbje {
    width: 41px;
    height: 41px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-19929li0i5u {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.41);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-19929li0i5u div { box-sizing: content-box; }
  /* generated by https://loading.io/ */