@import '../../configs/variables';
.HEADER--{
    height: $headerHeight;
    background: white;
    .inner{
        width: 100%;
        height: 100%;
        max-width: 1536px;
        margin: 0px auto;
        padding: 0px 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logoLink{
            > img {
                // height: 0.357*$headerHeight;
                max-height: 6rem;
                max-width: 20rem;
            }
        }
        .loginLink{
            padding: 1rem 2rem;
            &:hover{
                color: #000;
            }
        }
    }
}