@import '../../../configs/variables';
.FORGOT_PASSWORD--{
    background-color: #DBDBDB;
    background-image: url('../../../images/premeeting/login-back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;

    .innerBox{
        width: 50rem;
        border-radius: 4rem;
        margin: auto auto auto 12rem;
        background-color: white;
        padding: 6rem 6rem 7rem;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        > img.logo{
            width: 170px;
        }
        > h2{
            font-size: 3.4rem;
            margin-top: 4rem;
        }
        > form{
            margin-top: 2rem;
            margin-bottom: 0px;
            > label{
                display: block;
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
            }
            > input{
                height: 6rem;
                width: 100%;
                background: white;
                border-radius: 1rem;
                margin: 0px;
                border: 1px solid grey;
                outline: none;
                padding: 0px 2rem;
                box-sizing: border-box;
                font-size: 2rem;
            }
            .sendLinkButton{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 7rem;
                font-size: 2rem;
                margin-top: 4rem;
            }
        }
    }
}