.WAITING_LIST--{
    height: fit-content;
    max-height: 50%;
    display: flex;
    flex-direction: column;
    .waitingTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem 1.5rem;
        > h4{
            font-size: 1.6rem;
            font-weight: 700;
        }
        > button.admitAll{
            height: 2.8rem;
            font-size: 1.4rem;
            padding: 0px 0.9rem;
            width: auto;
            margin-left: 0.7rem;
            display: flex;
            align-items: center;
        }
    }
    .waitingList{
        overflow-y: auto;
    }
}