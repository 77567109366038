.PARTICIPANTS_LIST--{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    h4.sectionTitle{
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: 700;
    }
    .searchBar{
        // height: 4rem;
        width: calc(100% - 4rem);
        padding: 0.8rem 1.6rem;
        margin: 1rem 2rem 2rem;
        border: 1px solid #D8D8D8;
        outline: none !important;
        border-radius: 1.4rem;
        display: flex;
        align-items: center;
        > img{
            width: 1.4rem;
        }
        > input{
            outline: none !important;
            border: none;
            flex: 1;
            font-size: 1.6rem;
            line-height: 1;
            padding-bottom: 3px;
            padding-left: 0.6rem;
        }
    }
    .listOfParticipants{
        overflow-y: auto;
    }
}