@import '../../../configs/variables';
.LOGIN--{
    // background-color: #2ac66da1;
    // background-image: linear-gradient(to bottom, #2ac66d , #2ac66da1);
    background-image: url('../../../images/premeeting/login-back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    .innerBox{
        width: 50rem;
        border-radius: 4rem;
        margin: auto auto auto 12rem;
        background-color: white;
        padding: 6rem 6rem 7rem;
        box-sizing: border-box;
        > img.logo{
            width: 210px;
        }
        > h2{
            font-size: 3.4rem;
            margin-top: 3rem;
        }
        h4.errorMessage{
            height: 6rem;
            margin-top: 3rem;
            margin-bottom: 3rem;
            background: #df48570a;
            border: 1px solid #dd3849;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 1rem;
        }
        .nameInputDiv{
            margin-top: 2rem;
            margin-bottom: 0px;
            &:first-child{
                margin-top: 4rem;
            }
            > label{
                display: block;
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
            }
            > input{
                height: 6rem;
                width: 100%;
                background: white;
                border-radius: 1rem;
                margin: 0px;
                border: 1px solid grey;
                outline: none;
                padding: 0px 2rem;
                box-sizing: border-box;
                font-size: 2rem;
            }
        }
        .forgotPasswordDiv{
            margin-top: 2rem;
            margin-bottom: 1.5rem;
            > a{
                color: #0080DC;
                font-size: 1.6rem
            }
        }
        .signinButton{
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 7rem;
            font-size: 2rem;
        }
        .dontHaveAccount{
            display: flex;
            margin-top: 2.4rem;
            font-size: 1.6rem;
            > a{
                color: $primaryButtonBackground;
                font-weight: 700;
            }
        }
    }
    &.scroll{
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    &.center{
        .innerBox{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.input-validation-error{
    color: darkred;
}