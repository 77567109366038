/*  
    IMPORTANT - Please do not alter anything on the left hand side of the ':' symbol
*/

//colors of the primary button
$primaryButtonBackground: #2AC66E;
$primaryButtonText: #fff;

//colors of the secondary button
$secondaryButtonBackground: #D8D8D8;
$secondaryButtonText: #000;

//waiting screens background color
$waitingScreensBackgroundColor: #f8f8f8;

//font color of hyperlinks
$hyperlinkTextColor: #0c6db8;

//height of the waiting page header bar
$headerHeight: 8.8rem;