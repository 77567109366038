@import '../../configs/variables';

html{
    font-size: 10px;
}
body{
    margin: 0px;
    font-size: 16px;
    background-color: #F8F8F8;
}

div, p, a, button, li, h1, h2, h3, h4, h5, h6, span, label, input, textarea {
    font-family: lato;
    box-sizing: border-box;
}

div, p, a button {
    font-size: 1.5rem;
}

.primary-button{
    background-color: $primaryButtonBackground;
    width: 100%;
    color: $primaryButtonText;
    font-weight: 700;
    filter: brightness(100%);
    cursor: pointer;
    border: none;
    outline: none !important;
    border-radius: 1rem;
    &:hover{
        filter: brightness(90%);
    }
}

.secondary-button{
    background-color: $secondaryButtonBackground;
    width: 100%;
    color: $secondaryButtonText;
    font-weight: 700;
    filter: brightness(100%);
    cursor: pointer;
    border: none;
    outline: none !important;
    border-radius: 1rem;
    &:hover{
        filter: brightness(90%);
    }
}

.fillPrimaryColor{
    fill: $primaryButtonBackground;
}

.buildDate-text{
    font-size: 12px;
    margin-bottom: 0px;
    position: fixed;
    left: 10px;
    bottom: 5px;
    color: grey;
}