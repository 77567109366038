@import '../../../configs/variables';
.NARROW_SIDEBAR--{
    background: #fff;
    min-height: 100vh;
    overflow: hidden;
    > .inner{
        display: flex;
        justify-content: center;
        padding-top: 2rem;
        .expandButton{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &.highlight{
                &::after{
                    content: '';
                    width: 4rem;
                    height: 4rem;
                    border: 1px solid $primaryButtonBackground;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    animation-name: ani;
                    -webkit-animation-name: ani;
                    animation-iteration-count: infinite;
                    -webkit-animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    -webkit-animation-timing-function: linear;
                    animation-duration: 0.6s;
                    -webkit-animation-duration: 0.6s;
                    animation-direction: normal;
                    -webkit-animation-direction: normal;
                    border-radius: 1rem;
                }
            }
            > img{
                width: 2.2rem;
            }
            > span {
                display: block;
                position: absolute;
                z-index: 2;
                right: -5px;
                top: -5px;
                background-color: white;
                color: rgb(75, 75, 75);
                font-size: 1rem;
                min-width: 1.4rem;
                min-height: 1.4rem;
                padding: 0px 0.2rem;
                border-radius: 0.2rem;
                box-shadow: 1px -1px 2px rgba(22, 22, 22, 0.384);
            }
        }
        .viewNotes{
            background-color: #14857b;
            width: 100%;
            color: #fff;
            font-weight: 700;
            filter: brightness(100%);
            cursor: pointer;
            border: none;
            outline: none !important;
            border-radius: 1rem;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            > img{
                width: 2.2rem;
            }
            &:hover{
                filter: brightness(90%);
            }
        }
        .addNotes{
            background-color: #2a68da;
            width: 100%;
            color: #fff;
            font-weight: 700;
            filter: brightness(100%);
            cursor: pointer;
            border: none;
            outline: none !important;
            border-radius: 1rem;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            > img{
                width: 2.2rem;
            }
            &:hover{
                filter: brightness(90%);
            }
        }
    }
}

.viewNotePopup-content{
    background: white;
    padding: 1rem 0;
    color:rgb(75, 75, 75);
    border-radius: 0.5rem;
    max-width: 400px;
    // max-height: 400px;
    // overflow-y: auto;
    > div:last-child{
        padding: 0 1rem;
        max-height: 400px;
        overflow-y: auto;
    }
}

.addNotePopup-content{
    background: white;
    // padding: 1rem;
    color:rgb(75, 75, 75);
    border-radius: 0.5rem;
    .buttonWrap{
        display: flex;
        justify-content: flex-end;
        padding: 0.7rem 1rem;
        .updateButton{
            width: auto;
            padding: 0px 1rem;
            height: 3rem;
        }
    }
}

@keyframes ani {
    0% {-webkit-transform: translate(-50%, -50%) scale(1); opacity: 0} 
    1% {-webkit-transform: translate(-50%, -50%) scale(1); opacity: 1}
    50% {-webkit-transform: translate(-50%, -50%) scale(1.15); opacity: 0.6}
    95% {-webkit-transform: translate(-50%, -50%) scale(1.37); opacity: 0.2;}
    100% {-webkit-transform: translate(-50%, -50%) scale(1.4); opacity: 0;}
}

@-webkit-keyframes ani {
    0% {-webkit-transform: translate(-50%, -50%) scale(1); opacity: 0} 
    1% {-webkit-transform: translate(-50%, -50%) scale(1); opacity: 1}
    50% {-webkit-transform: translate(-50%, -50%) scale(1.15); opacity: 0.6}
    95% {-webkit-transform: translate(-50%, -50%) scale(1.37); opacity: 0.2;}
    100% {-webkit-transform: translate(-50%, -50%) scale(1.4); opacity: 0;}
}

.note-editor-wrap.rdw-editor-wrapper{
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.5rem 0.5rem 0 0;
    .rdw-editor-toolbar{
      border: none;
      border-bottom: 1px solid #f3f3f3;
      margin-bottom: 0px;
      .rdw-block-wrapper{
          .rdw-block-dropdown{
            font-size: 1.2rem;
          }
      }
      .rdw-fontsize-wrapper{
        .rdw-fontsize-dropdown{
          min-width: 5.6rem;
          font-size: 1.2rem;
        }
      }
      .rdw-list-wrapper{
        .rdw-option-wrapper{
          height: 25px;
          width: 30px;
          &.rdw-option-active{
            background-color: #eee;
          }
        }
      }
      .rdw-dropdown-carettoopen{
        border-top: 5px solid rgb(26, 26, 26);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
    .note-editor.rdw-editor-main{
      height: 200px;
      padding: 0px 1rem;
      background: rgb(241, 241, 241);
      .public-DraftStyleDefault-block{
          margin: 0.8rem 0px;
      }
      .DraftEditor-root{
          height: unset;
      }
    }
  }