.JOIN_REQUEST_REJECTED--{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner{
        display: flex;
        justify-content: center;
        align-items: center;
        > img{
            width: 8rem;
        }
        .rightSide{
            margin-left: 1.8rem;
            > h1{
                color: darkred;
                margin-bottom: 0.2rem;
            }
            > div{
                font-size: 1.8rem;
            }
        }
    }
}