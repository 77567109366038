.SIDEBAR--{
    background: #fff;
    min-height: 100vh;
    position: relative;
    .allParticipantsSection{
        height: calc(100vh - 4.5rem - 8rem);
        display: flex;
        flex-direction: column;
    }
    .bottomButtonsSection{
        height: 4.5rem;
        position: absolute;
        width: 100%;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}